<template>
  <div class="about__carousel">
    <div class="carousel__top">
      <button @click.prevent="slidePrev" class="about__carousel__btn btn__left">
        <svg
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M1 9.00024L11 17.3336"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M0.999999 9.00007L11 0.666749"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>
      </button>

      <button
          @click.prevent="slideNext"
          class="about__carousel__btn btn__right"
      >
        <svg
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M11 8.99976L1 0.666436"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M11 8.99993L1 17.3333"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>
      </button>
      <div class="scroll__blocker blocker__left"></div>
      <div class="scroll__blocker blocker__right"></div>
      <hooper
          class="carousel__images"
          :settings="upperSettings"
          group="group1"
          ref="carousel"
      >
        <slide v-for="(item, index) in carouselItems" :key="index">
          <img :src="item.image" alt=""/>
        </slide>
      </hooper>
    </div>
  </div>
</template>

<script>
import {Hooper, Slide} from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "AboutCarousel",
  props: {
    carouselItems: [],
  },
  data() {
    return {
      upperSettings: {
        itemsToShow: 1,
        infiniteScroll: true,
        centerMode: true,
        trimWhiteSpace: true,
        wheelControl: false,
        breakpoints: {
          1000: {
            itemsToShow: 5,
          },
          800: {
            itemsToShow: 3,
          },
          500: {
            itemsToShow: 2,
          },
        },
      },
    };
  },
  components: {
    Hooper,
    Slide,
  },
  methods: {
    slidePrev() {
      this.btnColorLeft = "white";
      this.btnColorRight = "#C49955";
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.btnColorLeft = "#C49955";
      this.btnColorRight = "white";
      this.$refs.carousel.slideNext();
    },
  },
};
</script>

<style lang="scss">
@import "src/sass/variables";

.about__carousel {
  .carousel__bottom,
  .carousel__text {
    display: none;
  }
}

li img {
  filter: brightness(0.5);
  transition: 0.5s;
}

.is-current img {
  filter: brightness(1);
}

.about__carousel {
  //max-width: 1600px;
  width: 100%;

  .carousel__top {
    position: relative;

    .about__carousel__btn {
      outline: none;
      position: absolute;
      z-index: 1;
      width: 40px;
      height: 56px;
      background-color: rgba(1, 1, 1, 0.4);
      border: none;
      border-radius: 2px;
      top: 45%;
      transform: translate(0, -50%);
      color: white;
    }

    .about__carousel__btn:focus {
      background-color: rgba(1, 1, 1, 0.6);
    }

    .btn__left {
      left: 25%;
      z-index: 3;
    }

    .btn__right {
      right: 25%;
      z-index: 3;
    }

    .scroll__blocker {
      display: block;
      position: absolute;
      height: 100%;
      width: 25%;
      z-index: 2;
    }

    .blocker__left {
      left: 0;
    }

    .blocker__right {
      right: 0;
    }

    .carousel__images {
      height: 400px;

      .hooper-slide {
        img {
          object-fit: cover;
          width: 101%;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .about__carousel {
    .btn__left {
      left: 5%!important;
    }

    .btn__right {
      right: 5%!important;
    }

    .scroll__blocker {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .about__carousel {
    .carousel__top {
      height: 413px;
    }
  }
}
</style>