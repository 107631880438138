<template>
  <default-layout class="about-layout">
    <Menu :color="(scrolled)? 'scrolledClass':'notScrolled'"/>
    <div class="header" :style="{'background': `url('${bg}')`}">
      <div class="about__content">
        <h2>{{ aboutHeader }}</h2>
        <div class="content__col">
          <div class="col__item">
            <p class="item-t">{{ aboutEstYear.visible_name }}</p>
            <p class="item-c">{{ aboutEstYear.value }}</p>
          </div>
          <div class="col__item">
            <p class="item-t">{{ aboutImport.visible_name }}</p>
            <p class="item-c">{{ aboutImport.value }}</p>
          </div>
          <div class="col__item">
            <p class="item-t">{{ aboutBrands.visible_name }}</p>
            <p class="item-c">{{ aboutBrands.value }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="about">
        <div class="wrapper">
          <section class="about">
            <div class="about-col">
              <div class="orange-rec"></div>
              <div class="d-orange-div is-display-none">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="col-img" :style="{backgroundImage: `url('${section[0].img}')`}"></div>
            </div>
            <div class="about-col">
              <div class="d-orange-div is-col-div-none">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="col-content">
                <h2 class="content-title">{{ section[0].title }}</h2>
                <p class="content-p" v-html="section[0].main_text">
                </p>

              </div>
            </div>
          </section>

        </div>
        <div class="retail__carousel">
          <carousel :carouselItems="carouselItems"></carousel>
        </div>
        <div class="d-orange-div under-b">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <section class="philosophy-company" id="philosophy-company">
          <div class="black-bg"></div>
          <div class="content-block">
            <div class="block-col-item">
              <h2 class="content-title is-color-white">{{ section[1].title }}</h2>
              <p class="content-p is-color-white" v-html="section[1].main_text">
              </p>

            </div>
            <div class="block-col-item">
              <div class="col-item-bg-img" :style="{backgroundImage: `url('${section[1].img}')`}"></div>
            </div>
          </div>
        </section>
        <section class="about-brut">
          <div class="limit__container">
            <div class="block-bg-img" :style="{backgroundImage: `url('${section[2].img}')`}"></div>
            <div class="brut-background">
              <img src='images/about/brut-background.svg' alt="">
            </div>
            <div class="block-content">
              <h2 class="content-title">{{ section[2].title }}</h2>
              <p class="content-p" v-html="section[2].main_text">
              </p>

            </div>
          </div>
        </section>
      </div>
    </div>
  </default-layout>
</template>


<script>
import AboutCarousel from "@/components/About/AboutCarousel";
import Menu from "../components/Menu";
import axios from "axios";

export default {
  name: "About",
  components: {
    Menu: Menu,
    carousel: AboutCarousel,
  },
  data() {
    return {
      section: Array,
      scrolled: false,
      windowWidth: window.innerWidth,
      bannerImage: '',
      aboutImport: '',
      aboutBrands: '',
      aboutEstYear: '',
      aboutHeader: '',
      carouselItems: [],
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToAnchorPoint() {
      if (this.$route.hash) {
        let container = document.getElementById('philosophy-company').offsetTop
        window.scroll(0, container)
      }
    },
    handleScroll() {
      this.scrolled = window.scrollY > 100;
    },
  },
  computed: {
    bg: function () {
      if (this.bannerImage.length) {
        if (this.windowWidth < 376) {
          return this.bannerImage[1].image_mobile
        } else if (this.windowWidth > 376 && this.windowWidth < 800) {
          return this.bannerImage[1].image_laptop
        } else {
          return this.bannerImage[1].image
        }
      } else {
        return '/images/home/banner.jpg'
      }
    }
  },
  mounted() {
    window.setTimeout(this.scrollToAnchorPoint, 500)
    axios.get('/api/about-section').then(response => (this.section = response.data.sections))
    axios.get('/api/banners').then(r => (this.bannerImage = r.data.banners))

    axios.get('/api/settings/about.import').then(r => (this.aboutImport = r.data.setting))
    axios.get('/api/settings/about.brands').then(r => (this.aboutBrands = r.data.setting))
    axios.get('/api/settings/about.est_year').then(r => (this.aboutEstYear = r.data.setting))

    axios.get('/api/banners?position=about').then(r => (this.aboutHeader = r.data.banners[0].title))


    axios.get('/api/banners?position=about.photo').then(r => (this.carouselItems = r.data.banners))


  }
};
</script>

<style lang="scss">
@import "src/sass/variables";

.about-layout {
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .about__content {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 5px;
    box-sizing: border-box;
  }

  .about__content h2 {
    display: inline-block;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin-bottom: 85px;
    color: #ffffff;
  }

  .retail__carousel {
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -o-user-select: none !important;
    user-select: none !important;
  }

  .content__col {
    justify-content: space-between;
    width: 1120px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .item-t {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #ffffff;
    padding: 20px;
    position: relative;
  }

  .item-t:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 1px;
    background: white;
  }

  .item-c {
    max-width: 212px;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #ffffff;
  }

  .limit__about__container {
    max-width: 1440px;
    margin: 0 auto;
  }

  .about__content h2 {
    display: inline-block;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin-bottom: 85px;
    color: #ffffff;
  }

  section.about {
    margin-top: 24px;
    display: flex;
    /*flex-wrap: wrap;*/
    flex-direction: row;
  }

  .about-col:first-child {
    margin-right: 40px;
  }

  .about-col:last-child {
    margin-left: 40px;
  }

  .about-col {
    position: relative;
    width: 50%;
  }

  .orange-rec {
    position: absolute;
    top: 0;
    left: 0;
    width: 1400px;
    margin-left: -900px;
    height: 166px;
    background: #ccad7b;
  }

  .col-img {
    float: right;
    margin-top: 24px;
    position: relative;
    z-index: 20;
    width: 480px;
    height: 612px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .d-orange-div {
    margin-top: 28px;
    margin-right: 5.5%;
    margin-left: 6.6%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .d-orange-div div {
    background: #ccad7b;
    height: 64px;
  }

  .d-orange-div div:nth-child(1) {
    width: 2.6%;
  }

  .d-orange-div div:nth-child(2) {
    width: 5.2%;
  }

  .d-orange-div div:nth-child(3) {
    width: 6.6%;
  }

  .d-orange-div div:nth-child(4) {
    width: 9.2%;
  }

  .d-orange-div div:nth-child(5) {
    width: 65%;
  }

  .col-content {
    margin-top: 128px;
  }

  .is-display-none {
    display: none;
  }

  .content-title {
    font-weight: 250;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #010101;
  }

  .content-p {
    margin-bottom: 50px;
    max-width: 560px;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #010101;
  }

  .under-b {
    /* row-reverse added for about page decoration, it needs to be flipped/mirrored */
    flex-direction: row-reverse;
    margin-left: 5.6%;
    margin-right: unset;
    max-width: 605px;
    width: 100%;
  }

  .philosophy-company {
    position: relative;
    padding-top: 28px;
    box-sizing: border-box;
  }

  .block-col-item:first-child {
    margin-right: 117px;
  }

  .block-col-item {
    width: 45%;
  }

  .black-bg {
    position: absolute;
    z-index: 1;
    background: #222222;
    width: 100%;
    height: 514px;
  }

  .content-block {
    position: relative;
    z-index: 3;
    background: transparent;
    display: flex;
    justify-content: center;
    margin: 80px 5.6%;
  }

  .is-color-white {
    color: white;
    margin-left: 100px;
    width: 100%;
  }

  .col-item-bg-img {
    height: 636px;
    width: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .limit__container {
    display: flex;
    flex-direction: row;
    margin: 120px auto;
    align-items: center;
    height: 612px;
    width: 1120px;
    background: #eedfd2;
    position: relative;
  }

  .brut-background {
    position: absolute;
    width: 367px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 10;
  }

  .col-content .content-p, .block-col-item p, .block-content p {
    margin-top: 40px;
    font-family: $font-secondary;
  }

  .brut-background img {
    width: 100%;
    height: 100%;
  }

  .limit__container .block-bg-img {
    margin-right: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 480px;
  }

  .block-content .content-title {
    margin: 0 0 54px 0;
  }

  .block-content .content-p {
    margin: 15px 0;
  }

  @media screen and (max-width: 1320px) {
    .orange-rec {
      margin-left: -1000px;
    }
  }

  @media screen and (max-width: 1170px) {
    .about__content h2 {
      margin-bottom: 10px;
    }

    .is-color-white {
      margin-left: 0;
    }

    .content__col {
      width: max-content;
    }

    .about__content h2 {
      margin-bottom: 10px;
    }

    .black-bg {
      height: 563px;
    }

    .block-col-item .col-item-bg-img {
      width: 445px;
      height: 563px;
    }

    .limit__container {
      height: 558px;
      width: 990px;
    }

    .limit__container .block-content {
      padding: 0 20px 0 0;
    }
  }

  @media screen and (max-width: 1030px) {
    section.about {
      margin-bottom: 50px;
    }

    .about-col:first-child {
      margin-right: 10px;
    }

    .about-col:first-child {
      margin-left: 10px;
    }

    .d-orange-div {
      margin-left: 0;
      margin-top: 28px;
    }

    .about-col .col-content {
      margin-top: 40px;
    }

    .about-col .col-content .content-p {
      margin-bottom: 10px;
    }

    .limit__container {
      height: 475px;
      width: 100%;
    }

    .philosophy-company .content-block {
      margin: 32px 0;
      max-width: 1440px;
      padding: 0;
      width: 100%;
    }

    .block-col-item:first-child {
      margin-right: 63px;
    }

    .content-block .block-col-item {
      width: 50%;
    }

    .block-col-item .col-item-bg-img {
      width: 407px;
      height: 495px;
    }
  }

  @media screen and (max-width: 905px) {
    .header {
      margin-top: -1px;
    }

    .philosophy-company {
      padding-top: 127px;
    }

    .content__col {
      flex-direction: column;
    }

    .about__content {
      margin-top: 0%;
    }

    .content-title {
      font-size: 16px;
      margin-bottom: 16px !important;
      margin-top: 24px;
    }

    .about__content h2 {
      width: 212px;
      margin: 0 auto;
      font-size: 16px;
      line-height: 36px;
    }

    .col__item .item-t {
      font-size: 16px;
      line-height: 16px;
    }

    .col__item .item-c {
      font-size: 16px;
      line-height: 26px;
    }

    section.about {
      position: relative;
      align-items: center;
      flex-direction: column;
    }

    .about-col {
      width: 565px;
      position: unset;
    }

    .is-col-div-none {
      display: none;
    }

    .is-display-none {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
    }

    .d-orange-div {
      height: 40px;
    }

    .about-col:last-child {
      margin: 0;
    }

    .col-content .content-p {
      width: 100%;
    }

    .col-img {
      margin-top: 129px;
      width: 100%;
      height: 720px;
    }

    .about-col .orange-rec {
      display: none;
    }

    .d-orange-div {
      width: 100%;
      position: absolute;
    }

    .content-block {
      flex-direction: column;
      align-items: center;
    }

    .black-bg {
      height: 679px;
    }

    .content-block .block-col-item:first-child {
      order: 2;
      margin-right: 0;
      width: 565px;
    }

    .content-block .block-col-item:last-child {
      order: 1;
      width: 565px;
      margin-bottom: 50px;
    }

    .block-col-item:first-child h2,
    .block-col-item:first-child p {
      color: black;
      max-width: 100%;
    }

    .block-col-item .col-item-bg-img {
      height: 718px;
      width: 100%;
    }

    .limit__container {
      box-sizing: border-box;
      margin: 50px auto;
      padding: 24px 0px;
      align-items: center;
      flex-direction: column;
      height: max-content;
    }

    .limit__container .block-bg-img {
      margin-bottom: 40px;
      height: 713px;
      width: 565px;
      margin-right: 0;
    }

    .limit__container .block-content {
      padding: 0;
      width: 565px;
    }

    .block-content .content-p {
      width: 100%;
    }
  }
  @media screen and (max-width: 425px) {

  }
  @media screen and (max-width: 620px) {
    .about-col {
      width: 95%;
    }

    .content-block .block-col-item:first-child,
    .content-block .block-col-item:last-child {
      width: 95%;
    }

    .limit__container .block-bg-img {
      width: 95%;
    }

    .limit__container .block-content {
      width: 95%;
    }
  }

  @media screen and (max-width: 605px) {
    .about .wrapper {
      padding: 0 !important;
    }

    .col-img {
      /*margin-left: 24px;*/
      /*margin-right: 24px;*/
    }
  }

  @media screen and (max-width: 450px) {
    .limit__container {
      margin: 0 auto;
    }

    .col-img {
      height: 450px;
    }

    .block-col-item .col-item-bg-img {
      height: 455px;
    }

    .black-bg {
      height: 422px;
    }

    .limit__container .block-bg-img {
      height: 437px;
    }
  }

  @media screen and (max-width: 375px) {
    .col-img {
      height: 359px;
    }

    .block-col-item .col-item-bg-img {
      height: 343px;
    }

    .black-bg {
      height: 345px;
    }

    .limit__container .block-bg-img {
      height: 327px;
    }

    .block-content .content-title {
      margin: 0 0 16px 0;
    }

    .block-col-item .content-p {
      margin-bottom: 10px;
    }

    .block-col-item:first-child h2 {
      margin: 0;
    }
  }

}
</style>